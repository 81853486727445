<template>
  <div>
    <div class="section footer-section">
      <app-store-links
        class="footer-section__section footer-section__app-store-links"
      />
      <div class="footer-section__container--left">
        <social-media-links class="footer-section__section" />
        <div class="footer-page-links-container">
          <site-links class="footer-section__section" />
          <studio-links class="footer-section__section" />
          <contact-us class="footer-section__section" />
        </div>
      </div>
    </div>
    <div class="copywrite">
      <p class="text--no-margin text--align-left">Copywrite @ 2021</p>
    </div>
  </div>
</template>
<script>
import AppStoreLinks from "./AppStoreLinks.vue";
import SocialMediaLinks from "./SocialMediaLinks.vue";
import SiteLinks from "./SiteLinks.vue";
import StudioLinks from "./StudioLinks.vue";
import ContactUs from "./ContactUs.vue";
export default {
  name: "footer-section",
  components: {
    AppStoreLinks,
    SocialMediaLinks,
    SiteLinks,
    StudioLinks,
    ContactUs,
  },
};
</script>
<style lang="scss" scoped>
.footer-section {
  position: relative;
  background-color: #00704a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1410px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 100px;
  }
}
.footer-section__section {
  padding: 10px;
  @media only screen and (min-width: 1410px) {
    padding: 10px 50px;
  }
}
// .footer-section__app-store-links {
// }
.footer-page-links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1410px) {
    width: auto;
    flex-direction: row;
    align-items: flex-start;
  }
}
.footer-section__container--left {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1410px) {
    width: auto;
    flex-direction: row-reverse;
  }
}
.copywrite {
  color: #ababab;
  background-color: #004a31;
  padding: 15px 25px;
  font-size: 0.8rem;
}
</style>
