<template>
  <div id="our-space-section">
    <our-space-banner />
    <div class="section our-space-section-text">
      <p class="text--short text--align-left">
        OSPACE is available for your booking and use 24 hours a day, 365 days a
        year. Book for slots as short as 30 minutes, or as long as 8 hours!
      </p>
      <a href="https://forms.gle/5pAuxLPTrxxvVRAu7" target="_blank">
        <button class="button">Enquire Now</button>
      </a>
    </div>
  </div>
</template>
<script>
import OurSpaceBanner from "./OurSpaceBanner.vue";
export default {
  name: "our-space",
  components: {
    OurSpaceBanner,
  },
};
</script>
<style lang="scss" scoped>
.our-space-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 640px) {
    p {
      text-align: center;
    }
  }
}
</style>
