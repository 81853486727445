<template>
  <button
    id="scroll-to-top-btn-id"
    class="button scroll-to-top-button button--circle button--fixed-bottom"
    @click="scrollToTop()"
  >
    <font-awesome-icon :icon="['fa', 'chevron-up']" size="lg" />
  </button>
</template>
<script>
export default {
  name: "top-button",
  props: ["scrollDirection"],
  methods: {
    scrollToTop() {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        window.scrollTo(0, top);
      }
    },
  },
  watch: {
    scrollDirection: function () {
      if (this.scrollDirection == 1) {
        document.getElementById("scroll-to-top-btn-id").classList.add("active");
      }
      if (this.scrollDirection == 0) {
        document
          .getElementById("scroll-to-top-btn-id")
          .classList.remove("active");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-to-top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border: none;
  background-color: rgba(0, 95, 63, 0.295);
  &.active {
    background-color: #005f3f;
  }
  @media only screen and (max-width: 640px) {
    display: none;
  }
}
.button--circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.button--fixed-bottom {
  position: fixed;
  bottom: 100px;
  right: 60px;
}
</style>
