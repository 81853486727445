<template>
  <div class="section about-us-section">
    <p class="text--short">
      For convenient fitness, anytime, anywhere; think OSPACE
    </p>
  </div>
</template>
<script>
export default {
  name: "about-us",
};
</script>
<style lang="scss" scoped>
.about-us-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
