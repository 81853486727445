<template>
  <div class="app-store-links-wrapper">
    <p class="app-store-title">START NOW</p>
    <a target="_blank" href="https://apps.apple.com/sg/app/ospace/id1606617283">
      <img
        class="app-store-img apple"
        :src="require(`@/assets/images/footer/apple-store-badge.png`)"
        alt="google-play-badge"
    /></a>
    <a
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.ospacesg"
    >
      <img
        class="app-store-img"
        :src="require(`@/assets/images/footer/google-play-badge.png`)"
        alt="google-play-badge"
    /></a>
  </div>
</template>
<script>
export default {
  name: "app-store-links",
};
</script>
<style lang="scss" scoped>
.app-store-title {
  padding-left: 2px;
}
.app-store-links-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (min-width: 1410px) {
    width: auto;
  }
}
.app-store-img {
  width: 200px;
  height: auto;
}
</style>
