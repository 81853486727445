<template>
  <div class="navbar-section">
    <nav-bar :scrollDirection="scrollDirection" />
    <top-button :scrollDirection="scrollDirection" />
  </div>
</template>
<script>
import NavBar from "@/components/nav/NavBar.vue";
import TopButton from "@/components/nav/TopButton.vue";
export default {
  components: { NavBar, TopButton },
  name: "nav-bar-section",
  data() {
    return {
      scrollDirection: 1,
      prevScrollHeight: 0,
    };
  },
  methods: {
    handleScroll() {
      let scrollHeightNow = window.scrollY;
      if (scrollHeightNow > 55) {
        if (this.prevScrollHeight > window.scrollY) {
          this.onScrollUp();
        } else {
          this.onScrollDown();
        }
        this.prevScrollHeight = scrollHeightNow;
      }
    },
    onScrollUp() {
      this.scrollDirection = 1;
    },
    onScrollDown() {
      this.scrollDirection = 0;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
