<template>
  <div id="app">
    <nav-bar-section />
    <router-view />
    <footer-section />
  </div>
</template>

<script>
import FooterSection from "@/components/footer/FooterSection.vue";
import NavBarSection from "@/components/nav/NavBarSection.vue";
export default {
  name: "App",
  components: {
    FooterSection,
    NavBarSection,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
body {
  margin: 0;
  position: relative;
}
html {
  scroll-behavior: smooth;
  position: relative;
  height: 100%;
}
</style>
