<template>
  <div class="circle dark" v-if="dark">
    <div class="checkmark"></div>
  </div>
  <div class="circle light" v-else>
    <div class="checkmark"></div>
  </div>
</template>
<script>
export default {
  name: "check-mark",
  props: ["dark"],
};
</script>
<style lang="scss" scoped>
$primary-dark: #ffffff;
$checkmarkColor-dark: #2e6334;
$primary-light: #000000;
$checkmarkColor-light: #f7f7f7;
$size: 20px;
$checkmarkFat: 2px;

.circle {
  position: relative;
  border-radius: 50%;
  width: $size;
  height: $size;
  margin-right: 20px;

  &.dark {
    background: $primary-dark;
    .checkmark {
      border-bottom: $checkmarkFat solid $checkmarkColor-dark;
      border-right: $checkmarkFat solid $checkmarkColor-dark;
    }
  }

  &.light {
    background: $primary-light;
    .checkmark {
      border-bottom: $checkmarkFat solid $checkmarkColor-light;
      border-right: $checkmarkFat solid $checkmarkColor-light;
    }
  }
}

.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 30%;
  top: 50%;
  height: $size/3;
  width: $size/6;
}
</style>
