import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from "vue-meta";
import "./assets/global_style.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronUp,
  faChevronRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronUp, faChevronRight, faCaretDown);
Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueMeta);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
