<template>
  <home-page />
</template>
<script>
import HomePage from "@/components/home/HomePage.vue";
export default {
  name: "Home",
  components: {
    HomePage,
  },
  metaInfo: {
    title: "OSPACE | Empowering individuals",
    meta: [
      {
        name: "description",
        content:
          "Train and have fun in complete privacy and safety! Simple, fuss-free booking anwhere, anytime",
      },
    ],
  },
};
</script>
<style lang="scss" scoped></style>
