<template>
  <div class="studio-card-wrapper">
    <router-link :to="`/location/${route}`">
      <div class="card studio-card" id="studio-card">
        <div class="overlay">
          <h2 class="overlay__location-title text--no-margin">{{ title }}</h2>
          <h2 class="overlay__coming-soon-text" v-if="isCarousel">
            Coming Soon
          </h2>
          <p v-else>Coming Soon</p>
        </div>

        <picture>
          <source
            :srcset="
              require(`@/assets/images/locations/${location}/thumbnail.webp`)
            "
            type="image/webp"
          />
          <img
            class="studio-thumbnail"
            :src="
              require(`@/assets/images/locations/${location}/thumbnail.jpg`)
            "
            :alt="`${title}-studio-img`"
          />
        </picture>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "studio-card",
  props: ["location", "route", "title", "isCarousel"],
};
</script>
<style lang="scss" scoped>
.studio-card-wrapper {
  padding: 20px 0px;
  position: relative;
  cursor: pointer;
  width: calc(100vw - 60px);
  transition: transform 0.2s; /* Animation */
  border-radius: 5px;
  height: 100%;

  @media only screen and (min-width: 700px) {
    padding: 0;
    &:hover {
      -webkit-transform: scale(1.04);
      -moz-transform: scale(1.04);
      transform: scale(1.04);
    }
  }
}
.studio-card {
  position: relative;
  &:hover {
    .img-overlay {
      background-color: rgba(39, 39, 39, 0.75);
    }
  }
}
.studio-thumbnail {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.location-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00704a;
  height: 45px;
  top: calc(50% - 45px);
  padding: 0 25px;
  border-radius: 0px 5px 5px 0px;
  color: white;
  font-family: "Didact Gothic", sans-serif;
  @media only screen and (min-width: 700px) {
    width: 70%;
    max-width: 250px;
    height: 60px;
  }
}
.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Didact Gothic", sans-serif;
  background-color: rgba(168, 168, 168, 0.7);
  color: black;
  h2 {
    margin: 40px 0px;
  }
  .overlay__coming-soon-text {
    display: none;
  }
  &:hover {
    .overlay__location-title {
      display: none;
    }
    .overlay__coming-soon-text {
      display: block;
    }
  }
}
</style>
