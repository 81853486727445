<template>
  <div>
    <pricing-card
      v-for="(pricing, index) in price"
      :key="index"
      :pricing="pricing"
      :index="index"
    />
  </div>
</template>
<script>
import PricingCard from "./PricingCard.vue";
export default {
  components: { PricingCard },
  name: "pricing-card-section",
  data() {
    return {
      price: [
        {
          cardTitle: "Solo Pass",
          cardDetails: [
            "1 Pax",
            "$9 for 30 Mins",
            "$18 for 60 Mins",
            "$9 per 30 Mins after 60 Mins",
          ],
        },
        {
          cardTitle: "Bring a Friend",
          cardDetails: [
            "2 Pax",
            "$11 for 30 Mins",
            "$20 for 60 Mins",
            "$10 per 30 Mins after 60 Mins",
          ],
        },
        {
          cardTitle: "Own The Gym",
          cardDetails: [
            "Full Studio",
            "$14 for 30 Mins",
            "$24 for 60 Mins",
            "$12 per 30 Mins after 60 Mins",
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
