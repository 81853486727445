<template>
  <vueper-slides
    class="no-shadow"
    :visible-slides="3"
    :slide-ratio="1 / 2.7"
    :dragging-distance="70"
    :gap="3"
  >
    <vueper-slide v-for="(studio, index) in studios" :key="index">
      <template #content>
        <div class="vueperslide__content-wrapper" style="flex-direction: row">
          <studio-card
            v-if="studio.isLocationReady"
            :location="studio.location"
            :title="studio.title"
            :route="studio.route"
          />
          <studio-card-coming-soon
            v-else
            :location="studio.location"
            :title="studio.title"
            :route="studio.route"
            :isCarousel="true"
          />
        </div>
      </template>
    </vueper-slide>
  </vueper-slides>
</template>
<script>
import StudioCard from "./StudioCard.vue";

import StudioCardComingSoon from "./StudioCardComingSoon.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "studio-carousel",
  props: ["studios"],
  components: { VueperSlides, VueperSlide, StudioCard, StudioCardComingSoon },
};
</script>
<style lang="scss" scoped>
.vueperslides {
  padding-top: 30px;
  width: 100%;
  max-width: 1200px;
  @media only screen and (min-width: 640px) {
    padding-top: 60px;
  }
}
.vueperslide {
  background-color: black;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 500ms;
  border-radius: 5px;
  background-color: transparent !important;
}
.vueperslide--active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
</style>
