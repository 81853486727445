<template>
  <div>
    <div class="section pricing-section" id="pricing-section">
      <div class="section__container">
        <h1 class="section__title section__title--left">
          Transparent Pricing For You
        </h1>
        <pricing-table v-if="windowWidth > 1080" />
        <div v-else>
          <pricing-card-section />
        </div>
        <button
          class="button book-now-button"
          @click="handleOpenDownloadAppModal()"
        >
          Book Now
        </button>
      </div>
    </div>
    <download-app-modal
      v-if="isDownloadAppModalOpen"
      @close="handleCloseDownloadAppModal()"
    />
  </div>
</template>
<script>
import PricingTable from "./PricingTable.vue";
import DownloadAppModal from "@/components/DownloadAppModal.vue";
import PricingCardSection from "./PricingCardSection.vue";
export default {
  name: "pricing-section",
  components: { DownloadAppModal, PricingTable, PricingCardSection },
  data() {
    return {
      isDownloadAppModalOpen: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    handleOpenDownloadAppModal() {
      if (window.innerWidth > 640) {
        this.isDownloadAppModalOpen = true;
        document.documentElement.style.overflowY = "hidden";
      } else {
        window.location.replace("/downloadApp");
      }
    },
    handleCloseDownloadAppModal() {
      this.isDownloadAppModalOpen = false;
      document.documentElement.style.overflowY = "scroll";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    mobileView() {
      return this.windowWidth <= 1200;
    },
    isLocationRoute() {
      return this.$route.name == "Locate Us";
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>
<style lang="scss" scoped>
.section__title {
  margin-bottom: 30px;
  @media only screen and (min-width: 640px) {
    margin-bottom: 60px;
  }
}
.book-now-button {
  margin-top: 120px;
  @media only screen and (min-width: 640px) {
    margin-top: 150px;
  }
}
</style>
