<template>
  <div>
    <home-hero />
    <about-us />
    <studio-section />
    <pricing-section />
    <our-space />
  </div>
</template>

<script>
import HomeHero from "./HomeHero.vue";
import AboutUs from "./AboutUs.vue";
import OurSpace from "../space/OurSpace.vue";
import StudioSection from "../studio/StudioSection.vue";
import PricingSection from "../pricing/PricingSection.vue";
export default {
  name: "home-page",
  components: {
    HomeHero,
    AboutUs,
    OurSpace,
    StudioSection,
    PricingSection,
  },
};
</script>
