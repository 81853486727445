<template>
  <div class="section section--grey" id="studio-section">
    <h1 class="section__title section__title--left">The Studios</h1>
    <studio-carousel :studios="studios" v-if="!mobileView" />
    <div v-for="(studio, index) in studios" :key="index" v-else>
      <studio-card
        v-if="studio.isLocationReady"
        :location="studio.location"
        :title="studio.title"
        :route="studio.route"
      />
      <studio-card-coming-soon
        v-else
        :location="studio.location"
        :title="studio.title"
        :route="studio.route"
        :isCarousel="false"
      />
    </div>
  </div>
</template>
<script>
import StudioCard from "./StudioCard.vue";
import StudioCardComingSoon from "./StudioCardComingSoon.vue";
import StudioCarousel from "./StudioCarousel.vue";
export default {
  name: "studio-section",
  components: { StudioCard, StudioCarousel, StudioCardComingSoon },
  data() {
    return {
      studios: [
        {
          location: "amk",
          route: "amk",
          title: "Ang Mo Kio",
          isLocationReady: true,
        },
        {
          location: "tampines",
          route: "tampines",
          title: "Tampines",
          isLocationReady: true,
        },
        {
          location: "bedoknorth",
          route: "bedoknorth",
          title: "Bedok North",
          isLocationReady: true,
        },
        {
          location: "tanglinHalt",
          route: "tanglin-halt",
          title: "Tanglin Halt",
          isLocationReady: true,
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    mobileView() {
      return this.windowWidth <= 700;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
.vueperslides {
  padding-top: 30px;
  width: 100%;
  max-width: 1200px;
  @media only screen and (min-width: 640px) {
    padding-top: 60px;
  }
}
.vueperslide {
  background-color: black;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  transition: all 500ms;
  border-radius: 5px;
  background-color: transparent !important;
}
.vueperslide--active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
</style>
