<template>
  <div class="menu">
    <ul>
      <router-link tag="li" to="/" exact>OSPACE</router-link>
      <router-link tag="li" to="/about">About Us</router-link>
      <li @click="workWithUs()">Work With Us</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "nav-menu",
  methods: {
    workWithUs() {
      window.open("https://forms.gle/5pAuxLPTrxxvVRAu7", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 3;
  ul {
    list-style: none;
    padding: 100px 0 0 0;
    li {
      cursor: pointer;
      padding: 30px 10px;
      width: 50%;
      margin: auto;
      color: white;
      text-decoration: none;
      font-size: 1.5rem; //h2
      &.router-link-exact-active {
        color: #686868;
      }
      &:hover {
        color: #00704a;
      }
    }
  }
  //   @media only screen and (min-height: 640px) {
  //     padding-top: 150px;
  //   }
}
</style>
