<template>
  <div class="pricing-card-wrapper">
    <div class="pricing-card card dark" v-if="index == 0">
      <h1>{{ pricing.cardTitle }}</h1>
      <p class="text--align-left text--grey">What you'll get</p>
      <div
        class="pricing-card__detail-wrapper"
        v-for="(item, index) in pricing.cardDetails"
        :key="index"
      >
        <check-mark :dark="true" />
        <p>
          <b>{{ item }}</b>
        </p>
      </div>
    </div>
    <div class="pricing-card card light" v-else>
      <h1>{{ pricing.cardTitle }}</h1>
      <p class="text--align-left text--grey">What you'll get</p>
      <div
        class="pricing-card__detail-wrapper"
        v-for="(item, index) in pricing.cardDetails"
        :key="index"
      >
        <check-mark :dark="false" />
        <p>
          <b>{{ item }}</b>
        </p>
      </div>
    </div>
    <img
      v-if="index == 0"
      class="pricing-card__img"
      :src="require(`@/assets/images/price/solo-pass.png`)"
      alt="our-space"
    />
    <img
      v-if="index == 1"
      class="pricing-card__img"
      :src="require(`@/assets/images/price/bring-a-friend.png`)"
      alt="our-space"
    />
    <img
      v-if="index == 2"
      class="pricing-card__img"
      :src="require(`@/assets/images/price/own-the-gym.png`)"
      alt="our-space"
    />
  </div>
</template>
<script>
import CheckMark from "./CheckMark.vue";
export default {
  components: {
    CheckMark,
  },
  props: ["pricing", "index"],
};
</script>
<style lang="scss" scoped>
.pricing-card-wrapper {
  position: relative;
}
.pricing-card {
  height: auto;
  width: auto;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  padding-bottom: 220px;
  @media only screen and (min-width: 880px) {
    padding-bottom: 30px;
  }

  &.dark {
    background-color: #2e6334;
    color: white;
  }
  &.light {
    background-color: #f7f7f7;
    color: black;
  }
}
.text--grey {
  color: #a3a3a3;
}
.pricing-card__detail-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.pricing-card__img {
  position: absolute;
  max-height: 200px;
  max-width: 100%;
  bottom: 0;
  right: 0;
}
</style>
