import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Meta from "vue-meta";

Vue.use(Meta);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/location/:location",
    name: "Locate Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Location.vue"),
  },
  {
    path: "/downloadApp",
    name: "Book Now",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BookNow.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy Notice",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Privacy.vue"),
  },
  {
    path: "/serviceagreement",
    name: "Service Agreement",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ServiceAgreement.vue"),
  },
  {
    path: "/workwithus",
    beforeEnter() {
      window.location.replace("https://forms.gle/5pAuxLPTrxxvVRAu7");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 };
    }
  },
});
export default router;
