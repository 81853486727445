<template>
  <div>
    <div class="home-hero-section">
      <div class="home-hero-section__overlay" />
      <vueper-slides
        class="no-shadow"
        :slide-ratio="5 / 16"
        :dragging-distance="50"
        autoplay
      >
        <vueper-slide v-for="(image, index) in imageData" :key="index">
          <template #content>
            <a v-if="image.hasLink" :href="image.link" target="_blank">
              <picture>
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-web.webp`)
                  "
                  media="(min-width:1201px) and (max-width: 1920px)"
                  type="image/webp"
                />
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-web.jpg`)
                  "
                  media="(min-width:1201px) and (max-width: 1920px)"
                  type="image/jpeg"
                />
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-wide.webp`)
                  "
                  media="(min-width:1921px)"
                  type="image/webp"
                />
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-wide.jpg`)
                  "
                  media="(min-width:1921px)"
                  type="image/jpeg"
                />
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-mobile.webp`)
                  "
                  media=" (max-width: 1200px)"
                  type="image/webp"
                />
                <source
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-mobile.jpg`)
                  "
                  media=" (max-width: 1200px)"
                  type="image/jpeg"
                />
                <img
                  :srcset="
                    require(`@/assets/images/home/${image.fileName}-mobile.jpg`)
                  "
                  media=" (max-width: 1200px)"
                  type="image/jpeg"
                />
              </picture>
            </a>
            <picture v-else>
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-web.webp`)
                "
                media="(min-width:1201px) and (max-width: 1920px)"
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-web.jpg`)
                "
                media="(min-width:1201px) and (max-width: 1920px)"
                type="image/jpeg"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-wide.webp`)
                "
                media="(min-width:1921px)"
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-wide.jpg`)
                "
                media="(min-width:1921px)"
                type="image/jpeg"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-mobile.webp`)
                "
                media=" (max-width: 1200px)"
                type="image/webp"
              />
              <source
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-mobile.jpg`)
                "
                media=" (max-width: 1200px)"
                type="image/jpeg"
              />
              <img
                :srcset="
                  require(`@/assets/images/home/${image.fileName}-mobile.jpg`)
                "
                media=" (max-width: 1200px)"
                type="image/jpeg"
              />
            </picture>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "home-hero",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      imageData: [
        {
          fileName: "home-hero-img-clint",
          hasLink: true,
          link: "https://forms.gle/wdp9iQQQKTyi1u1C7",
        },
        {
          fileName: "home-hero-img-liz",
          hasLink: true,
          link: "https://forms.gle/wdp9iQQQKTyi1u1C7",
        },
        {
          fileName: "home-hero-img-peilin",
          hasLink: true,
          link: "https://forms.gle/wdp9iQQQKTyi1u1C7",
        },
        {
          fileName: "home-hero-img-edwin",
          hasLink: true,
          link: "https://forms.gle/wdp9iQQQKTyi1u1C7",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.asd {
  width: 100%;
}
.home-hero-section {
  height: auto;
  width: 100%;
  margin-top: 65px;
  position: relative;
}
.home-hero-section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.5);
}
.home-hero-section__banner-img {
  width: 100%;
  height: auto;
}
picture,
img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
</style>
