<template>
  <div>
    <nav class="navbar" id="navbar-id">
      <div class="navbar__logo-wrapper">
        <router-link to="/" class="navbar__logo-router-link" exact>
          <img
            :src="require(`@/assets/logo.png`)"
            alt="Ospace logo"
            class="navbar__logo"
        /></router-link>
      </div>
      <div
        id="toggle-btn-id"
        class="toggle-btn"
        @click="toggleSideBar()"
        v-if="mobileView"
      >
        <div id="hamburger-icon-id">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="nav-links-container" v-else>
        <router-link to="/" exact class="nav-links">Home</router-link>
        <router-link to="/about" class="nav-links">About Us</router-link>
        <div class="dropdown-button">
          <a
            class="nav-link-dropdown"
            :class="{ 'router-link-active': isLocationRoute }"
            >Find a Studio</a
          ><font-awesome-icon :icon="['fa', 'caret-down']" />
          <div class="dropdown-content">
            <ul>
              <li>
                <router-link to="/location/amk" class="nav-links"
                  >Ang Mo Kio</router-link
                >
              </li>
              <li>
                <router-link to="/location/bedoknorth" class="nav-links"
                  >Bedok North</router-link
                >
              </li>
              <li>
                <router-link to="/location/tampines" class="nav-links"
                  >Tampines</router-link
                >
              </li>
              <li>
                <router-link to="/location/tanglin-halt" class="nav-links"
                  >Tanglin Halt</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="nav-menu-wrapper" id="nav-menu-id">
      <nav-menu v-if="mobileView" />
    </div>
  </div>
</template>
<script>
import NavMenu from "./NavMenu.vue";
export default {
  name: "navbar",
  props: ["scrollDirection"],
  components: {
    NavMenu,
  },
  data() {
    return {
      isMenuActive: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    toggleSideBar() {
      document.getElementById("toggle-btn-id").classList.toggle("active");
      document.getElementById("nav-menu-id").classList.toggle("active");
      if (this.isMenuActive) {
        document.body.style.overflowY = "scroll";
      } else {
        document.body.style.overflowY = "hidden";
      }
      this.isMenuActive = !this.isMenuActive;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    scrollDirection: function () {
      if (this.scrollDirection == 1) {
        document.getElementById("navbar-id").style.top = "0px";
      }
      if (this.scrollDirection == 0) {
        document.getElementById("navbar-id").style.top = "-70px";
      }
    },
    $route: function () {
      var menu_bar = document.getElementById("nav-menu-id");
      if (menu_bar.classList) {
        if (menu_bar.classList.contains("active")) {
          menu_bar.classList.remove("active");
        }
      }
      if (this.isMenuActive) {
        var toggle_btn = document.getElementById("toggle-btn-id");
        if (toggle_btn.classList) {
          if (toggle_btn.classList.contains("active")) {
            toggle_btn.classList.remove("active");
          }
        }
        this.isMenuActive = false;
      }
      document.body.style.overflowY = "scroll";
    },
  },
  computed: {
    mobileView() {
      return this.windowWidth <= 1200;
    },
    isLocationRoute() {
      return this.$route.name == "Locate Us";
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  height: 65px;
  width: 100%;
  top: 0px;
  background-color: #00704a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.nav-menu-wrapper {
  position: fixed;
  top: -110vh;
  width: 100%;
  height: 100vh;
  z-index: 4;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  opacity: 0;
  &.active {
    opacity: 1;
    top: 0px;
  }
}
.nav-links-container {
  display: flex;
  padding: 0px 45px;
}
.nav-links {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  margin: 2em 1em;
  padding-bottom: 3px;
  font-weight: lighter;
  transition: 300ms all ease-out;

  &::after {
    display: block;
    content: "";
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
  }
  &.router-link-active,
  &:hover {
    text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;

    &::after {
      transform: scaleX(1);
    }
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #6868686c;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
  margin-right: 40px;
  a {
    float: none;
    text-decoration: none;
    display: block;
    text-align: center;
    color: white;
    margin: 2em !important;
  }
  ul {
    list-style: none;
    padding: 0px;
    text-align: center;
  }
}
.dropdown-button {
  .nav-link-dropdown {
    margin: 2em 10px 0 1em !important;
    position: relative;
    display: inline-block;
    color: white;
    text-decoration: none;
    margin: 2em;
    padding-bottom: 3px;
    font-weight: lighter;
    transition: 300ms all ease-out;
    cursor: pointer;
    &::after {
      display: block;
      content: "";
      border-bottom: solid 1px white;
      transform: scaleX(0);
      transition: transform 300ms ease-in-out;
    }
    &.router-link-active {
      text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;

      &::after {
        transform: scaleX(1);
      }
    }
  }
  svg {
    color: rgb(235, 235, 235);
  }
}
.dropdown-button:hover .dropdown-content {
  display: block;
}
.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 20px;
  height: 100%;
  @media screen and (min-width: 1200px) {
    padding: 0px 20px;
  }
  &.active {
    #hamburger-icon-id span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -16.3px;
      left: 7px;
    }

    #hamburger-icon-id span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    #hamburger-icon-id span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 8.5px;
      left: 7px;
    }
  }
}
#hamburger-icon-id {
  width: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

#hamburger-icon-id span:nth-child(1) {
  top: -9px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon-id span:nth-child(2) {
  top: -2px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#hamburger-icon-id span:nth-child(3) {
  top: 5px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar__logo-wrapper {
  padding: 0px 20px;
  @media screen and (min-width: 1200px) {
    padding: 0px 30px;
  }
}
.navbar__logo-router-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.navbar__logo {
  height: 40px;
}
</style>
