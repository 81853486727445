<template>
  <div class="download-app">
    <div class="download-app-modal">
      <h1 class="section__title">Start Today</h1>
      <div class="close-wrapper" @click="$emit('close')">
        <div class="close" />
      </div>
      <p>Our App will be up soon!</p>
      <div class="qr-code-wrapper">
        <img
          class="qr-code-img"
          :src="require(`@/assets/qr-code.png`)"
          alt="product-pic"
        />
      </div>
      <p>Scan QR to download app for booking</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "download-app-modal",
};
</script>
<style lang="scss" scoped>
.download-app {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-app-modal {
  position: relative;
  background: #00704a;
  border: 7px solid #2b303a;
  border-radius: 5px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
}
.qr-code-wrapper {
  margin: 20px 0;
  background: white;
  padding: 10px;
  border-radius: 34px;
  color: black;
}
.qr-code-img {
  height: 200px;
  width: 200px;
  display: block;
}
.section__title {
  color: white;
}
.close-wrapper {
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
}
.close:after {
  color: black;
  font-size: 60px;
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}
</style>
