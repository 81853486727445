<template>
  <div class="studio-card-wrapper">
    <router-link :to="`/location/${route}`">
      <div class="card studio-card" id="studio-card">
        <div class="img-overlay" />
        <picture>
          <source
            :srcset="
              require(`@/assets/images/locations/${location}/thumbnail.webp`)
            "
            type="image/webp"
          />
          <img
            class="studio-thumbnail"
            :src="
              require(`@/assets/images/locations/${location}/thumbnail.jpg`)
            "
            :alt="`${title}-studio-img`"
          />
        </picture>

        <div class="location-label">
          <h2 class="text--no-margin">{{ title }}</h2>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "studio-card",
  props: ["location", "route", "title"],
};
</script>
<style lang="scss" scoped>
.studio-card-wrapper {
  padding: 20px 0px;
  position: relative;
  cursor: pointer;
  width: calc(100vw - 60px);
  transition: transform 0.2s; /* Animation */
  border-radius: 5px;
  height: 100%;

  @media only screen and (min-width: 700px) {
    padding: 0;
    &:hover {
      -webkit-transform: scale(1.04);
      -moz-transform: scale(1.04);
      transform: scale(1.04);
    }
  }
}
.studio-card {
  position: relative;
  &:hover {
    .img-overlay {
      background-color: rgba(39, 39, 39, 0.75);
    }
  }
}
.studio-thumbnail {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.location-label {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00704a;
  height: 45px;
  top: calc(50% - 45px);
  padding: 0 25px;
  border-radius: 0px 5px 5px 0px;
  color: white;
  font-family: "Didact Gothic", sans-serif;
  @media only screen and (min-width: 700px) {
    width: 70%;
    max-width: 250px;
    height: 60px;
  }
}
.img-overlay {
  border-radius: 5px;
}
</style>
